import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-sdk/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from '../components/Homepage.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      